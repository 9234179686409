import styled from 'styled-components';

interface GridGeneratorProps {
  columns: number;
  additionalColumnSettings?: Array<{
    maxScreenSize: number;
    columns: number;
  }>;
  gap?: string;
}

const GridGenerator = styled.div<GridGeneratorProps>`
  display: grid;
  ${({ gap }) => {
    if (gap) {
      return `gap: ${gap}`;
    }
  }};
  grid-template-columns: ${({ columns }) => {
    return `repeat(${columns}, 1fr);`;
  }};

  ${({ additionalColumnSettings }) => {
    if (additionalColumnSettings?.length) {
      return additionalColumnSettings
        .sort((a, b) => {
          return a.maxScreenSize - b.maxScreenSize;
        })
        .reduce((acc, setting, index) => {
          const mediaQuery = `
              @media screen
              ${
                additionalColumnSettings[index - 1]?.maxScreenSize
                  ? `and (min-width: ${additionalColumnSettings[index - 1].maxScreenSize}px)`
                  : ''
              }
              and (max-width: ${setting.maxScreenSize}px) {
              grid-template-columns: repeat(${setting.columns}, 1fr);
            }`;

          return acc + mediaQuery;
        }, '');
    }
  }};
`;

export default GridGenerator;
