export default Object.freeze({
  primaryColor: '#1c303e',
  primaryColorShade2: '#044b66',
  primaryColorShade3: '#282c34',
  secondaryColor: '#2d2d2d',
  secondaryColorShade2: '#333',
  secondaryColorShade3: '#676767',
  secondaryColorShade4: '#aaa',
  secondaryColorShade5: '#999',
  tertiaryColor: '#f3f3f3',
  tertiaryColorShade1: '#e7e7e7',
  white: '#fff',
});
