import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../constants/styleConstants';
import pxToRem from '../../helpers/pxToRem';

interface FrameTextProps {
  style?: any;
}

const StyledFrameTextWrapper = styled.div`
  position: relative;
  margin-top: 70px;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    left: 80px;
    bottom: -70px;
    border: 7px solid rgba(255, 255, 255, 0.2);
    width: 50%;
    height: 200%;
    z-index: -1;
    transition: all 300ms linear 0s;

    @media (max-width: ${breakpoints.md}px) {
      bottom: -20%;
      height: 140%;
    }
  }
`;

const StyledFrameText = styled.div`
  position: relative;
  margin-bottom: 10px;
  line-height: 160%;
  color: ${(props) => props.theme.white};
  font-size: ${pxToRem(29)};
  font-weight: 700;
`;

const FrameText: React.FC<FrameTextProps> = (props) => {
  const { style, children } = props;

  return (
    <StyledFrameTextWrapper style={style}>
      <StyledFrameText>{children}</StyledFrameText>
    </StyledFrameTextWrapper>
  );
};

export default FrameText;
