import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { breakpoints } from '../constants/styleConstants';

export const MobileContext = React.createContext(false);

const MobileProvider: React.FC = ({ children }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.md}px)`,
  });

  return <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>;
};

export default MobileProvider;
