import magon1LoungeImg from './images/magon-1-lounge-20220325-min.jpg';
import magon1LoungeImg2 from './images/magon-1-lounge2-20220325-min.jpg';
import magon1KitchenImg from './images/magon-1-kitchen-min.jpg';
import magon1RoomImg from './images/magon-1-room-20220325-min.jpg';
import magon1RoomImg2 from './images/magon-1-room2-20220325-min.jpg';
import magonOutsideImg from './images/magon-outside.jpg';
import magonOutsideImg2 from './images/magon-outside2.jpg';

import magon2LoungeImg from './images/magon-2-lounge-min.jpg';
import magon2LoungeImg2 from './images/magon-2-lounge2-min.jpg';
import magon2KitchenImg from './images/magon-2-kitchen-min.jpg';
import magon2KitchenImg2 from './images/magon-2-kitchen2-min.jpg';
import magon2RoomImg from './images/magon-2-room-min.jpg';
import magon2RoomImg2 from './images/magon-2-room2-min.jpg';
import magon2BathroomImg from './images/magon-2-bathroom-min.jpg';
import magon2BathroomImg2 from './images/magon-2-bathroom2-min.jpg';

import suliacLoungeImg from './images/suliac-lounge-min.jpg';
import suliacLoungeImg2 from './images/suliac-lounge2-min.jpg';
import suliacOutsideImg from './images/suliac-outside-min.jpg';
import suliacKitchenImg from './images/suliac-kitchen-min.jpg';
import suliacRoomImg from './images/suliac-room-min.jpg';
import suliacRoomImg2 from './images/suliac-room2-min.jpg';
import suliacRoomImg3 from './images/suliac-room3-min.jpg';
import suliacRoomImg4 from './images/suliac-room4-min.jpg';
import suliacRoomImg5 from './images/suliac-room5-min.jpg';

export const rentalData = [
  {
    title: 'Magon I',
    propertyType: 'Appartement',
    slug: 'magon-1',
    mainImg: magon1LoungeImg,
    mainImgAlt: 'Salon appartement Magon I',
    mainDescription:
      "L'appartement en location est situé à 10' à pied du centre historique de Saint-Malo. Le T2 refait à neuf se " +
      'trouve à proximité des commerces, du port de plaisance et de la plage. Idéal pour passer un agréable séjour ' +
      'en Bretagne, cet appartement saura vous séduire.',
    bookingUrl:
      // eslint-disable-next-line max-len
      'https://www.airbnb.fr/rooms/51358115?guests=1&adults=1&s=67&unique_share_id=582c4f47-7142-4762-9a0d-5549f62abc71',
    videoUrl: 'https://www.youtube.com/embed/MVbvXzmQfZ4',
    photos: [
      { url: magon1LoungeImg, alt: 'Salon' },
      { url: magon1LoungeImg2, alt: 'Salon' },
      { url: magon1KitchenImg, alt: 'Cuisine' },
      { url: magon1RoomImg, alt: 'Chambre' },
      { url: magon1RoomImg2, alt: 'Chambre' },
      { url: magonOutsideImg, alt: 'Extérieur' },
      { url: magonOutsideImg2, alt: 'Extérieur' },
    ],
    outsideImg: null,
    distanceFromBeach: 60,
    maxPeople: 2,
    bathroom: null,
    waterCloset: null,
    surfaceArea: 42,
    floor: 2,
    downTown: '15min à pied',
    loungeAmenities: ['Fauteuils', 'Dressing', 'Chaîne Hifi', 'Table basse', 'TV', 'Meuble TV', 'Lecteur DVD', 'Wifi'],
    roomAmenities: ['Lit double', 'Couette', 'Oreillers', '2 tables de chevet', 'Armoire', 'Cintres', 'Fer à repasser'],
    kitchenAmenities: [
      'Four',
      'Micro-ondes',
      'Lave-vaisselle',
      'Réfrigérateur',
      'Congélateur',
      'Cafetière',
      'Machine à café Senseo',
      'Grille-pain',
      'Bouilloire',
      'Lave-linge',
    ],
    bathroomAmenities: ['Douche', 'Vasque', 'WC séparé'],
    boilerRoomAmenities: [],
    description:
      '<p>' +
      "Appartement T2 situé au 16 rue Amiral Magon, au 2ème étage d'un immeuble du début du XIXe" +
      'siècle, dans un quartier calme et reposant.' +
      '</p>' +
      '<p>' +
      'Cet appartement a été refait à neuf dans un esprit chaleureux et convivial, à proximité du' +
      "port de plaisance à 60 m de la plage des Bas Sablons que l'on aperçoit des fenêtres, cette" +
      "location est à 15' à pied du centre historique et des remparts, le marché très réputé se" +
      'tient 2 fois par semaine à 200 m, en haut de la rue Amiral Magon.' +
      '</p>' +
      '<p>' +
      "Le secteur de la Tour Solidor avec ses expositions de peintures et d'antiquaires agrémentent" +
      'vos matinées estivales.' +
      '</p>',
    location: { latitude: 48.637758, longitude: -2.018522 },
  },
  {
    title: 'Magon II',
    propertyType: 'Appartement',
    slug: 'magon-2',
    mainImg: magon2LoungeImg,
    mainImgAlt: 'Salon appartement Magon II',
    mainDescription:
      'Location de vacances à St-Malo dans un appartement T2 de 42m² sur la côte d’Émeraude pouvant accueillir ' +
      "jusqu'à 4 personnes. Cette location à proximité des commerces est située à 50m de la plage et à 10min à pied " +
      'du centre historique de St-Malo.',
    bookingUrl:
      // eslint-disable-next-line max-len
      'https://www.airbnb.fr/rooms/51359916?guests=1&adults=1&s=67&unique_share_id=b2e7da8d-2934-4aa2-b87a-ee1c975ab443',
    videoUrl: 'https://www.youtube.com/embed/1cMP-UGi4ZY',
    photos: [
      { url: magon2LoungeImg, alt: 'Salon' },
      { url: magon2LoungeImg2, alt: 'Salon' },
      { url: magon2KitchenImg, alt: 'Cuisine' },
      { url: magon2KitchenImg2, alt: 'Cuisine' },
      { url: magon2RoomImg, alt: 'Chambre' },
      { url: magon2RoomImg2, alt: 'Chambre' },
      { url: magon2BathroomImg, alt: "Salle d'eau" },
      { url: magon2BathroomImg2, alt: "Salle d'eau" },
      { url: magonOutsideImg, alt: 'Extérieur' },
      { url: magonOutsideImg2, alt: 'Extérieur' },
    ],
    outsideImg: null,
    distanceFromBeach: 60,
    maxPeople: 4,
    bathroom: null,
    waterCloset: null,
    surfaceArea: 45,
    floor: 2,
    downTown: '15min à pied',
    loungeAmenities: [
      'Canapé convertible 2 places',
      'Fauteuils',
      'Table basse',
      'TV',
      'Lecteur DVD',
      'Wifi',
      'Table ronde',
      'Buffet bas',
      'Cheminée',
    ],
    roomAmenities: [
      'Lit double',
      'Couvertures',
      'Oreillers',
      '2 tables de chevet',
      'Dressing',
      'Cintres',
      'Fer à repasser',
    ],
    kitchenAmenities: [
      'Four',
      'Micro-ondes',
      'Lave-vaisselle',
      'Réfrigérateur',
      'Congélateur',
      'Cafetière',
      'Grille-pain',
      'Bouilloire',
    ],
    bathroomAmenities: ['Douche', 'Vasque', 'WC', 'Lave-linge'],
    boilerRoomAmenities: [],
    description:
      '<p>' +
      'Appartement de 45m² refait à neuf à proximité des commerces, du port de plaisance, à 60' +
      'mètres de la plage des Bas Sablons, à 15 minutes à pied du centre historique de Saint-Malo' +
      'intra muros.' +
      '</p>' +
      '<p>' +
      'Le marché réputé pour ses étales de produits frais a lieu 2 fois par semaine en haut de la' +
      'rue Amiral Magon.' +
      '</p>' +
      '<p>' +
      'Idéal pour passer un agréable séjour même sans voiture.' +
      'Cet appartement saura vous séduire.' +
      '</p>',
    location: { latitude: 48.637758, longitude: -2.018522 },
  },
  {
    title: 'Suliac',
    propertyType: 'Maison',
    slug: 'suliac',
    mainImg: suliacLoungeImg,
    mainImgAlt: 'Salon maison Suliac',
    mainDescription:
      'Agréable maison de pêcheur à 200 mètres du port, située en plein centre du village à proximité des commerces.',
    bookingUrl:
      // eslint-disable-next-line max-len
      'https://www.airbnb.fr/rooms/51336686?guests=1&adults=1&s=67&unique_share_id=6cdc4c1b-be58-4873-89f0-901288bf25c3',
    videoUrl: null,
    photos: [
      { url: suliacLoungeImg, alt: 'Salon' },
      { url: suliacLoungeImg2, alt: 'Salon' },
      { url: suliacKitchenImg, alt: 'Cuisine' },
      { url: suliacRoomImg, alt: 'Chambre' },
      { url: suliacRoomImg2, alt: 'Chambre' },
      { url: suliacRoomImg3, alt: 'Chambre' },
      { url: suliacRoomImg4, alt: 'Chambre' },
      { url: suliacRoomImg5, alt: 'Chambre' },
    ],
    outsideImg: suliacOutsideImg,
    distanceFromBeach: 200,
    maxPeople: 8,
    bathroom: 2,
    waterCloset: 3,
    surfaceArea: 130,
    floor: null,
    downTown: null,
    loungeAmenities: [
      'Canapé',
      'Fauteuils',
      'Table basse',
      'TV',
      'Lecteur DVD',
      'Wifi',
      'Table ronde',
      'Buffets',
      'Cheminée',
    ],
    roomAmenities: [
      '3 Lits double',
      '1 Lit superposé',
      'Couvertures',
      'Oreillers',
      'Tables de chevet',
      'Armoire',
      'Cintres',
      'Centrale vapeur',
    ],
    kitchenAmenities: [
      'Four',
      'Micro-ondes',
      'Lave-vaisselle',
      'Réfrigérateur',
      'Congélateur',
      'Cafetière',
      'Grille-pain',
      'Bouilloire',
    ],
    bathroomAmenities: ['2 Douches', 'Vasques', '3 WC séparés'],
    boilerRoomAmenities: ['Lave-linge'],
    description:
      '<p>' +
      'Agréable maison de pêcheur à 200 m. du port, située en plein centre du village à proximité' +
      'des commerces.' +
      '</p>' +
      '<p>' +
      'Cette maison chaleureuse rénovée avec gout vous accueille sur <strong>3 niveaux</strong>.<br>' +
      '<strong>Au rez de chaussée</strong>, un grand séjour de 45m² avec cuisine américaine donnant sur une' +
      'petite cour de 6m².<br>' +
      '<strong>Au 1<sup>ère</sup> étage :</strong> 2 chambres, une lingerie, une salle de bain avec douche,' +
      'un WC séparé.<br>' +
      '<strong>Au 2<sup>ème</sup> étage :</strong> une mezzanine, 2 chambres, une salle de bain avec douche,' +
      'un WC séparé.' +
      '</p>' +
      '<p>' +
      'Saint Suliac est un village classé parmi les <strong>plus beaux villages de France</strong>. Plusieurs' +
      "départs de sentiers pédestres pour découvrir l'estuaire de la Rance. Le <strong>club de voile</strong>" +
      'sur le port pourras vous accueillir vous et vos enfants. Les visites à faire :' +
      '</p>' +
      '<ul>' +
      '<li>Saint Malo et ses remparts à ~15km,</li>' +
      '<li>Dinan à ~20km,</li>' +
      '<li>Dinard à ~16km,</li>' +
      '<li>Mont Saint Michel à ~48km</li>' +
      '</ul>' +
      '<p>Désolé, mais les animaux ne sont plus admis.</p>',
    location: { latitude: 48.570637, longitude: -1.972637 },
  },
];
