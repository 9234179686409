// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const routeWithParam = (route: string, params: any): string => {
  const routeParts = route.split('/');

  return routeParts
    .map((part: string) => {
      if (!part.startsWith(':')) {
        return part;
      }
      const varName = part.substring(1);
      if (varName in params) {
        return params[varName];
      }
      return part;
    })
    .join('/');
};

export default routeWithParam;
