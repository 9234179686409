import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import analytics from '../helpers/analytics';

const GoogleAnalytics: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    analytics.sendPageview(currentPath);
  }, [location]);

  return null;
};

export default GoogleAnalytics;
