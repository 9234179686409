import styled from 'styled-components';
import { breakpoints } from '../../constants/styleConstants';

export const Container = styled.div`
  margin: auto;
  padding: 0 15px;
  width: 100%;

  @media screen and (max-width: ${breakpoints.sm}px) {
    max-width: 100%;
    width: auto;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    max-width: 540px;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    max-width: 720px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    max-width: 960px;
  }

  @media screen and (min-width: ${breakpoints.xl}px) {
    max-width: 1140px;
  }

  @media screen and (min-width: ${breakpoints.xxl}px) {
    max-width: 1320px;
  }
`;
