import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import routes from '../constants/routes';
import { breakpoints } from '../constants/styleConstants';
import pxToRem from '../helpers/pxToRem';
import logoImg from '../images/logo.png';
import { Container } from './shared/layoutStyle';
import GridGenerator from './shared/GridGenerator';

const StyledFooter = styled.footer`
  background: ${(props) => props.theme.primaryColorShade2};
  padding: 60px 0;
  color: ${(props) => props.theme.white};

  @media (max-width: ${breakpoints.sm}px) {
    padding: 30px 15px;
  }
`;

const StyledFooterTitle = styled.div`
  font-size: ${pxToRem(15)};
  font-weight: 500;

  &:after {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    background: ${(props) => props.theme.white};
    width: 30%;
    height: 1px;
    content: '';
  }
`;

const StyledList = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style: none;
`;

const StyledListItem = styled.li`
  padding: 5px 0;
  font-size: ${pxToRem(13)};
  font-weight: 500;
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.white};
  transition-duration: 0.3s;

  &:hover {
    color: ${(props) => props.theme.tertiaryColorShade1};
  }
`;

const Footer: React.FC = () => {
  return (
    <StyledFooter data-testid="footer">
      <Container>
        <GridGenerator
          columns={2}
          additionalColumnSettings={[
            {
              maxScreenSize: breakpoints.sm,
              columns: 1,
            },
          ]}
          gap="30px"
        >
          <div>
            <Link to={routes.HOME}>
              <img src={logoImg} alt="Locations Saint Malo" width="310" height="116" loading="lazy" />
            </Link>
            <p style={{ fontSize: pxToRem(12) }}>Appartements et maison de vacances à Saint-Malo en Bretagne.</p>
          </div>
          <address>
            <StyledFooterTitle>Contact</StyledFooterTitle>
            <StyledList>
              <StyledListItem>Par téléphone</StyledListItem>
              <StyledListItem>
                <StyledLink href="tel:+33686904551">+33 (0)6 86 90 45 51</StyledLink>
              </StyledListItem>
              <StyledListItem>Par email</StyledListItem>
              <StyledListItem>
                <StyledLink href="mailto:contact@locations-saint-malo.fr">contact@locations-saint-malo.fr</StyledLink>
              </StyledListItem>
            </StyledList>
          </address>
        </GridGenerator>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
