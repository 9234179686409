import ReactGA from 'react-ga';

function init(): void {
  ReactGA.initialize('UA-21183684-8', { debug: false });
}

function sendEvent(payload: any | null): void {
  ReactGA.event(payload);
}

function sendPageview(path: string): void {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageview,
};
