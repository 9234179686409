import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { Carousel } from 'antd';
import 'antd/lib/carousel/style/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCouch, faBed, faUtensils, faBath, faHome } from '@fortawesome/free-solid-svg-icons';

import { breakpoints } from '../constants/styleConstants';
import pxToRem from '../helpers/pxToRem';
import { Rental } from '../types/rental';
import { Container } from './shared/layoutStyle';
import FrameImg from './shared/FrameImg';
import CTAExternalLink from './shared/CTAExternalLink';
import GridGenerator from './shared/GridGenerator';
import GoogleMaps from './shared/GoogleMaps';
import { navBarHeight } from './Header';
import NotFound from './NotFound';
import { rentalData } from '../data';

const StyledMain = styled.main`
  padding-top: ${navBarHeight + 1 + 60}px;

  @media (max-width: ${breakpoints.md}px) {
    padding-top: ${navBarHeight + 1 + 30}px;
  }
`;

const StyledInfos = styled.aside`
  margin-left: 30px;

  @media (max-width: ${breakpoints.md}px) {
    margin-top: 30px;
    margin-left: 0;
  }
`;

const StyledTitle = styled.h1`
  line-height: 130%;
  font-weight: 600;
  font-size: ${pxToRem(35)};

  &:after {
    content: '';
    display: block;
    border-bottom: 4px solid ${(props) => props.theme.primaryColorShade2};
    padding-top: 15px;
    width: 130px;
  }

  @media (max-width: ${breakpoints.md}px) {
    margin-top: 30px;
  }
`;

const StyledSubTitle = styled(StyledTitle)`
  font-size: ${pxToRem(20)};
`;

const StyledGrid = styled.section`
  display: grid;
  gap: 30px;
  margin-bottom: 100px;
  grid-template-columns: 60% 1fr;

  @media (max-width: ${breakpoints.md}px) {
    display: block;
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.xxl}px) {
    grid-template-columns: 53% 1fr;
  }
`;

const StyledCarousel = styled(Carousel)`
  @media (min-width: ${breakpoints.md + 1}px) {
    &:before {
      content: '';
      position: absolute;
      top: 40px;
      left: 40px;
      border: 10px solid rgba(0, 0, 0, 0.05);
      width: 95%;
      height: 95%;
      z-index: -1;
    }
  }
`;

const StyledHighlights = styled.ul`
  margin: 30px 0 10px;
  padding-left: 0;
  list-style: none;
`;

const StyledHighlightItem = styled.li`
  padding: 13px 0;
  border-bottom: 1px solid ${(props) => props.theme.tertiaryColorShade1};
  font-weight: 600;
  font-size: ${pxToRem(14)};
`;

const StyledHighlightItemLabel = styled.span`
  display: inline-block;
  width: 100px;
  color: ${(props) => props.theme.secondaryColorShade5};

  @media (max-width: ${breakpoints.md}px) {
    width: 200px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 50%;
  }
`;

const StyledDescription = styled.div`
  margin-bottom: 15px;
  line-height: 200%;
  color: ${(props) => props.theme.secondaryColorShade3};
  font-size: ${pxToRem(13)};
  font-weight: 500;
`;

const StyledCard = styled.div`
  padding: 35px 30px 25px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
  text-align: center;
`;

const StyledCardTitle = styled.div`
  margin: 15px 0;
  line-height: 1.3;
  color: ${(props) => props.theme.secondaryColor};
  font-size: ${pxToRem(25)};
  font-weight: 500;
`;

const StyledCardList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0 35px;
  padding-left: 0;
  line-height: 170%;
  list-style: none;
  color: ${(props) => props.theme.secondaryColorShade3};
  font-size: ${pxToRem(15)};
  font-weight: 500;
`;

const StyledCardListItem = styled.li`
  padding-right: 5px;

  &:not(:last-child):after {
    content: ',';
  }
`;

const RentalInfos: React.FC = () => {
  const theme = useContext(ThemeContext);
  const { slug } = useParams<{ slug: string }>();

  const result: Rental | undefined = rentalData.find((rental: Rental) => rental.slug === slug);

  if (result === undefined) {
    return <NotFound />;
  }

  return (
    <StyledMain data-testid="rentalInfos">
      <Container>
        <StyledGrid>
          <div>
            {result.photos ? (
              <StyledCarousel autoplay dotPosition="right">
                {result.photos.map((photo) => {
                  return (
                    <div key={photo.url}>
                      <img src={photo.url} alt={photo.alt} width="700" height="467" />
                    </div>
                  );
                })}
              </StyledCarousel>
            ) : (
              <FrameImg urlImg={result.mainImg} altImg={result.mainImgAlt} />
            )}
          </div>
          <StyledInfos>
            <StyledTitle>{result.propertyType}</StyledTitle>
            <StyledHighlights>
              {result.maxPeople && (
                <StyledHighlightItem>
                  <StyledHighlightItemLabel>Voyageurs :</StyledHighlightItemLabel> {result.maxPeople}
                </StyledHighlightItem>
              )}
              {result.surfaceArea && (
                <StyledHighlightItem>
                  <StyledHighlightItemLabel>Superficie :</StyledHighlightItemLabel> {result.surfaceArea}m<sup>2</sup>
                </StyledHighlightItem>
              )}
              {result.floor && (
                <StyledHighlightItem>
                  <StyledHighlightItemLabel>Etage :</StyledHighlightItemLabel> {result.floor}
                  <sup>{result.floor > 1 ? 'ème' : 'er'}</sup>
                </StyledHighlightItem>
              )}
              {result.distanceFromBeach && (
                <StyledHighlightItem>
                  <StyledHighlightItemLabel>Plage :</StyledHighlightItemLabel> {result.distanceFromBeach} mètres
                </StyledHighlightItem>
              )}
              {result.downTown && (
                <StyledHighlightItem>
                  <StyledHighlightItemLabel>Centre historique :</StyledHighlightItemLabel> {result.downTown}
                </StyledHighlightItem>
              )}
              {result.bathroom && (
                <StyledHighlightItem>
                  <StyledHighlightItemLabel>SdB :</StyledHighlightItemLabel> {result.bathroom}
                </StyledHighlightItem>
              )}
              {result.waterCloset && (
                <StyledHighlightItem>
                  <StyledHighlightItemLabel>WC :</StyledHighlightItemLabel> {result.waterCloset}
                </StyledHighlightItem>
              )}
            </StyledHighlights>
            <CTAExternalLink to={result.bookingUrl} style={{ marginTop: '30px' }}>
              Réserver sur Airbnb
            </CTAExternalLink>
          </StyledInfos>
        </StyledGrid>
        <GridGenerator
          columns={2}
          additionalColumnSettings={[
            {
              maxScreenSize: breakpoints.md,
              columns: 1,
            },
          ]}
          gap="30px"
          style={{ marginBottom: '50px' }}
        >
          <div>
            <StyledSubTitle as="h2">Description</StyledSubTitle>
            <StyledDescription dangerouslySetInnerHTML={{ __html: result.description }} />
          </div>
          <div>
            {result.outsideImg && !result.videoUrl && <FrameImg urlImg={result.outsideImg} altImg="Vue extérieur" />}
            {result.videoUrl && (
              <iframe
                src={result.videoUrl}
                width="100%"
                height="315"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={`YouTube video player for ${result.title}`}
              />
            )}
          </div>
        </GridGenerator>
        <GridGenerator
          columns={2}
          additionalColumnSettings={[
            {
              maxScreenSize: breakpoints.md,
              columns: 1,
            },
          ]}
          gap="30px"
        >
          {!!result.loungeAmenities.length && (
            <StyledCard>
              <FontAwesomeIcon icon={faCouch} color={theme.primaryColorShade2} size="5x" />
              <StyledCardTitle>Salon</StyledCardTitle>
              <StyledCardList>
                {result.loungeAmenities.map((amenity) => {
                  return <StyledCardListItem key={amenity}>{amenity}</StyledCardListItem>;
                })}
              </StyledCardList>
            </StyledCard>
          )}
          {!!result.roomAmenities.length && (
            <StyledCard>
              <FontAwesomeIcon icon={faBed} color={theme.primaryColorShade2} size="5x" />
              <StyledCardTitle>Chambre</StyledCardTitle>
              <StyledCardList>
                {result.roomAmenities.map((amenity) => {
                  return <StyledCardListItem key={amenity}>{amenity}</StyledCardListItem>;
                })}
              </StyledCardList>
            </StyledCard>
          )}
          {!!result.kitchenAmenities.length && (
            <StyledCard>
              <FontAwesomeIcon icon={faUtensils} color={theme.primaryColorShade2} size="5x" />
              <StyledCardTitle>Cuisine</StyledCardTitle>
              <StyledCardList>
                {result.kitchenAmenities.map((amenity) => {
                  return <StyledCardListItem key={amenity}>{amenity}</StyledCardListItem>;
                })}
              </StyledCardList>
            </StyledCard>
          )}
          {!!result.bathroomAmenities.length && (
            <StyledCard>
              <FontAwesomeIcon icon={faBath} color={theme.primaryColorShade2} size="5x" />
              <StyledCardTitle>Salle de bain & WC</StyledCardTitle>
              <StyledCardList>
                {result.bathroomAmenities.map((amenity) => {
                  return <StyledCardListItem key={amenity}>{amenity}</StyledCardListItem>;
                })}
              </StyledCardList>
            </StyledCard>
          )}
          {!!result.boilerRoomAmenities.length && (
            <StyledCard>
              <FontAwesomeIcon icon={faHome} color={theme.primaryColorShade2} size="5x" />
              <StyledCardTitle>Chaufferie</StyledCardTitle>
              <StyledCardList>
                {result.boilerRoomAmenities.map((amenity) => {
                  return <StyledCardListItem key={amenity}>{amenity}</StyledCardListItem>;
                })}
              </StyledCardList>
            </StyledCard>
          )}
        </GridGenerator>
      </Container>
      <div style={{ marginTop: '70px' }}>
        <GoogleMaps latitude={result.location.latitude} longitude={result.location.longitude} />
      </div>
    </StyledMain>
  );
};

export default RentalInfos;
