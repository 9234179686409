import { createGlobalStyle } from 'styled-components';

import { ThemeType } from '../../types/theme';

type Props = {
  theme: ThemeType;
};

export const GlobalStyle = createGlobalStyle<Props>`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    color: ${(props) => props.theme.primaryColor};
    font-family: 'Montserrat', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1.3;
    color: ${(props) => props.theme.secondaryColor};
    font-family: 'Montserrat', sans-serif;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
  }

  h5,
  h6 {
    font-weight: 600;
  }

  img {
    max-width: 100%;
    height: auto;
    transition-duration: .3s;
  }

  address {
    font-style: normal;
  }

  a {
    color: ${(props) => props.theme.secondaryColor};
    text-decoration: none;
    transition-duration: .3s;
  }

  a:active, a:hover, a:focus {
    color: ${(props) => props.theme.secondaryColorShade2};
    outline: 0 none;
    text-decoration: none;
  }

  button {
    border: none;
    background: none;
    outline: 0 none;
  }

  iframe {
    border: 0;
  }

  input, textarea {
    max-width: 100%;
    outline: none;
  }

  ::-moz-selection {
    background: ${(props) => props.theme.secondaryColor};
    color: ${(props) => props.theme.white};
    text-shadow: none;
  }

  ::selection {
    background: ${(props) => props.theme.secondaryColor};
    color: ${(props) => props.theme.white};
    text-shadow: none;
  }
`;
