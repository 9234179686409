import React, { ReactElement } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

interface GoogleMapsProps {
  latitude: number;
  longitude: number;
  width?: string;
  height?: string;
  zoom?: number;
}

const GoogleMaps: React.FC<GoogleMapsProps> = ({ latitude, longitude, zoom, width, height }): ReactElement | null => {
  const GoogleMapsSettings = {
    key: 'AIzaSyCA83Hq8AY8YZs2Hi1FHWIW8CfrTdAABCM',
    zoom,
    containerStyle: {
      width,
      height,
    },
    position: {
      lat: latitude,
      lng: longitude,
    },
  };

  return (
    <LoadScript googleMapsApiKey={GoogleMapsSettings.key}>
      <GoogleMap
        mapContainerStyle={GoogleMapsSettings.containerStyle}
        zoom={GoogleMapsSettings.zoom}
        center={GoogleMapsSettings.position}
      >
        <Marker position={GoogleMapsSettings.position} />
      </GoogleMap>
    </LoadScript>
  );
};

GoogleMaps.defaultProps = {
  width: '100%',
  height: '400px',
  zoom: 14,
};

export default GoogleMaps;
