export const ROOT_FONT_SIZE = 16;

export const breakpoints = Object.freeze({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
});
