import React, { useContext } from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import { breakpoints } from '../../constants/styleConstants';
import { MobileContext } from '../MobileProvider';

interface FrameImgProps {
  urlImg: string;
  altImg: string;
  isParallax?: boolean;
  reverse?: boolean;
  style?: any;
}

interface StyledFrameImgProps {
  reverse: boolean;
}

const StyledFrameImg = styled.div<StyledFrameImgProps>`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border: 10px solid rgba(0, 0, 0, 0.05);
    width: 95%;
    height: 95%;
    z-index: -1;

    ${({ reverse }) => {
      if (reverse) {
        return `
          right: 40px;
          top: 40px;
        `;
      }
      return `
        top: 40px;
        left: 40px;
      `;
    }};

    @media (max-width: ${breakpoints.xl}px) {
      height: 70%;
    }

    @media (max-width: ${breakpoints.lg}px) {
      height: 40%;
    }

    @media (max-width: ${breakpoints.md}px) {
      display: none;
    }
  }
`;

const FrameImg: React.FC<FrameImgProps> = (props) => {
  const { urlImg, altImg, reverse = false, isParallax = false, style } = props;
  const isMobile = useContext(MobileContext);

  const displayImg = <img src={urlImg} alt={altImg} />;

  return (
    <StyledFrameImg reverse={reverse} style={style}>
      {isParallax ? (
        <Parallax y={['40px', '-40px']} disabled={isMobile}>
          {displayImg}
        </Parallax>
      ) : (
        displayImg
      )}
    </StyledFrameImg>
  );
};

export default FrameImg;
