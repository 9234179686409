import React, { useContext, useState, useRef } from 'react';
import styled, { ThemeContext, keyframes } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

import routes from '../constants/routes';
import { breakpoints } from '../constants/styleConstants';
import pxToRem from '../helpers/pxToRem';
import routeWithParam from '../helpers/routeWithParam';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import logoImg from '../images/logo.png';
import { Container } from './shared/layoutStyle';

interface StyledBurgerProps {
  isBurgerOpen: boolean;
}

const navItems = [
  { title: 'Accueil', route: routes.HOME },
  {
    title: 'Appartements',
    isSubNavButton: true,
    subNavItems: [
      { title: 'Magon I', route: routes.RENTAL, propertyType: 'appartement', slug: 'magon-1' },
      { title: 'Magon II', route: routes.RENTAL, propertyType: 'appartement', slug: 'magon-2' },
    ],
  },
  { title: 'Maison', route: routes.RENTAL, propertyType: 'maison', slug: 'suliac' },
];

const navBarMargin = 15;
const logoMaxHeight = 50;
export const navBarHeight = navBarMargin * 2 + logoMaxHeight;

const mobileNavFromTop = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(${navBarHeight}px);
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.white};
  padding: ${navBarMargin}px 0;
`;

const StyledNavbar = styled.nav`
  position: fixed;
  margin-bottom: 0;
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid ${(props) => props.theme.tertiaryColor};
  background: ${(props) => props.theme.white};
  width: 100%;
  min-height: 50px;
  z-index: 10;
`;

const StyledLogo = styled.img`
  margin-left: 15px;
  max-width: 100%;
  max-height: ${logoMaxHeight}px;
  height: auto;
  transition-duration: 0.3s;
  vertical-align: middle;
`;

const StyledNav = styled.ul`
  display: flex;
  margin: 0;
  padding-left: 0;
  list-style: none;
`;

const StyledSubNav = styled.ul`
  display: none;
  position: absolute;
  top: 100%;
  margin-left: 15px;
  box-shadow: 0 5px 9px 0 rgb(50 50 50 / 15%);
  background: ${(props) => props.theme.white};
  padding: 10px;
  min-width: 160px;
  font-size: ${pxToRem(14)};
  list-style: none;
  z-index: 10;
`;

const StyledNavItem = styled.li`
  position: relative;
  padding: 0 2px;

  &:hover ${StyledSubNav} {
    display: block;
  }
`;

const StyledNavItemLink = styled(NavLink)`
  position: relative;
  display: block;
  padding: 15px;
  line-height: 20px;
  font-size: ${pxToRem(10)};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.secondaryColor};
  transition-duration: 0.3s;

  &:hover {
    color: ${(props) => props.theme.primaryColorShade2};
  }
`;

const StyledSubNavButton = styled.span`
  display: block;
  padding: 15px;
  line-height: 20px;
  font-size: ${pxToRem(10)};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.secondaryColor};
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.primaryColorShade2};
  }
`;

const StyledNavCollapse = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${(props) => props.theme.tertiaryColorShade1};
  background: ${(props) => props.theme.white};
  padding-bottom: 5px;
  animation: ${mobileNavFromTop} 0.3s ease-in-out 1 forwards;
  z-index: -1;
`;

const StyledNavCollapseList = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style: none;
`;

const StyledNavCollapseItem = styled.li`
  padding: 5px 0 0 5px;
`;

const StyledNavCollapseItemLink = styled(StyledNavItemLink)`
  padding: 10px 15px;
`;

const StyledSubNavTitle = styled(StyledSubNavButton)`
  padding: 0 15px;
`;

const StyledBurger = styled.button<StyledBurgerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 15px;
  padding: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;

  span {
    position: relative;
    border-radius: 10px;
    background: ${(props) => props.theme.primaryColorShade2};
    width: 32px;
    height: 2px;
    transition: all 0.3s linear;
    transform-origin: 1px;

    &:first-child {
      transform: ${({ isBurgerOpen }) => (isBurgerOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      opacity: ${({ isBurgerOpen }) => (isBurgerOpen ? '0' : '1')};
      transform: ${({ isBurgerOpen }) => (isBurgerOpen ? 'translateX(20px)' : 'translateX(0)')};
    }

    &:nth-child(3) {
      transform: ${({ isBurgerOpen }) => (isBurgerOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const Header: React.FC = () => {
  const theme = useContext(ThemeContext);
  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.lg}px)`,
  });

  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);

  const subNavOpenerRef = useRef(null);

  useOnClickOutside(subNavOpenerRef, () => setIsMobileNavOpen(false));

  const activeStyle = {
    color: theme.primaryColorShade2,
  };

  return (
    <header style={{ background: theme.primaryColorShade3 }} data-testid="header">
      <StyledNavbar>
        <Container>
          <StyledWrapper>
            <div>
              <Link to={routes.HOME}>
                <StyledLogo src={logoImg} alt="logo" width="134" height="50" />
              </Link>
            </div>
            <div>
              {isMobile ? (
                <StyledBurger
                  type="button"
                  ref={subNavOpenerRef}
                  onClick={(): void => {
                    setIsMobileNavOpen(!isMobileNavOpen);
                  }}
                  aria-expanded={isMobileNavOpen}
                  aria-label="Navigation"
                  isBurgerOpen={isMobileNavOpen}
                >
                  <span />
                  <span />
                  <span />
                </StyledBurger>
              ) : (
                <StyledNav>
                  {navItems.map((item) => {
                    return (
                      <StyledNavItem key={item.title}>
                        {item.isSubNavButton ? (
                          <>
                            <StyledSubNavButton aria-expanded="false">
                              {item.title} <FontAwesomeIcon icon={faAngleDown} />
                            </StyledSubNavButton>
                            <StyledSubNav data-testid="subNav">
                              {item.subNavItems.map((subItem) => {
                                return (
                                  <li key={subItem.title}>
                                    <StyledNavItemLink
                                      to={routeWithParam(subItem.route, {
                                        propertyType: subItem.propertyType,
                                        slug: subItem.slug,
                                      })}
                                      activeStyle={activeStyle}
                                    >
                                      {subItem.title}
                                    </StyledNavItemLink>
                                  </li>
                                );
                              })}
                            </StyledSubNav>
                          </>
                        ) : (
                          <StyledNavItemLink
                            to={routeWithParam(item.route, { propertyType: item.propertyType, slug: item.slug })}
                            activeStyle={activeStyle}
                            exact
                          >
                            {item.title}
                          </StyledNavItemLink>
                        )}
                      </StyledNavItem>
                    );
                  })}
                </StyledNav>
              )}
            </div>
          </StyledWrapper>
          {isMobile && isMobileNavOpen && (
            <StyledNavCollapse aria-hidden={!isMobileNavOpen} data-testid="mobileNav">
              <Container>
                <StyledNavCollapseList>
                  {navItems.map((item) => {
                    return (
                      <StyledNavCollapseItem key={item.title}>
                        {item.isSubNavButton ? (
                          <StyledSubNavTitle>
                            {item.title} <FontAwesomeIcon icon={faAngleDown} />
                            <ul>
                              {item.subNavItems.map((subItem) => {
                                return (
                                  <li key={subItem.title}>
                                    <StyledNavCollapseItemLink
                                      to={routeWithParam(subItem.route, {
                                        propertyType: subItem.propertyType,
                                        slug: subItem.slug,
                                      })}
                                      activeStyle={activeStyle}
                                    >
                                      {subItem.title}
                                    </StyledNavCollapseItemLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </StyledSubNavTitle>
                        ) : (
                          <StyledNavCollapseItemLink
                            to={routeWithParam(item.route, { propertyType: item.propertyType, slug: item.slug })}
                            activeStyle={activeStyle}
                            exact
                          >
                            {item.title}
                          </StyledNavCollapseItemLink>
                        )}
                      </StyledNavCollapseItem>
                    );
                  })}
                </StyledNavCollapseList>
              </Container>
            </StyledNavCollapse>
          )}
        </Container>
      </StyledNavbar>
    </header>
  );
};

export default Header;
