import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUmbrellaBeach, faBed } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import { breakpoints } from '../constants/styleConstants';
import routes from '../constants/routes';
import pxToRem from '../helpers/pxToRem';
import routeWithParam from '../helpers/routeWithParam';
import { Container } from './shared/layoutStyle';
import CTALink from './shared/CTALink';
import GridGenerator from './shared/GridGenerator';
import FrameImg from './shared/FrameImg';
import FrameText from './shared/FrameText';
import { navBarHeight } from './Header';
import { MobileContext } from './MobileProvider';

import bgHeroImg from '../images/bg-saint-malo-min.jpg';
import bgKeyFiguresImg from '../images/bg-tour-solidor-min.jpg';

import { rentalData } from '../data';

interface StyledRentalProps {
  reverse: boolean;
}

interface StyledRentalInfoProps {
  reverse: boolean;
}

const StyledHero = styled.section`
  position: relative;
  background: url(${bgHeroImg}) no-repeat center bottom / cover;
  width: 100%;
  height: 50vh;
  min-height: 250px;
  z-index: -1;
`;

const StyledHeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px 40px;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  font-weight: 500;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 20px 30px;
  }
`;

const StyledHeroTitle = styled.h1`
  color: ${(props) => props.theme.white};
  font-size: ${pxToRem(55)};
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  letter-spacing: 4px;

  &:after {
    content: '';
    display: block;
    margin: 20px auto;
    width: 120px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: ${pxToRem(33)};
  }
`;

const StyledHeroSubTitle = styled.h2`
  margin-top: 20px;
  color: ${(props) => props.theme.white};
  font-size: ${pxToRem(17)};
  letter-spacing: 12px;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);

  @media (max-width: ${breakpoints.sm}px) {
    font-size: ${pxToRem(12)};
    letter-spacing: 7px;
  }
`;

const StyledRental = styled.article<StyledRentalProps>`
  display: grid;
  gap: 100px;
  margin-bottom: 120px;

  ${({ reverse }) => {
    if (reverse) {
      return `
        grid-template-columns: 1fr 55%;

        @media (max-width: ${breakpoints.lg}px) {
          grid-template-columns: 1fr 45%;
        }
      `;
    }
    return `
      grid-template-columns: 55% 1fr;

      @media (max-width: ${breakpoints.lg}px) {
        grid-template-columns: 45% 1fr;
      }
    `;
  }};

  @media (max-width: ${breakpoints.md}px) {
    display: block;
    margin-bottom: 80px;
  }
`;

const StyledRentalInfo = styled.div<StyledRentalInfoProps>`
  margin-top: 40px;

  ${({ reverse }) => {
    if (reverse) {
      return `grid-row: 1;`;
    }
  }};

  @media (max-width: ${breakpoints.sm}px) {
    margin-top: 30px;
  }
`;

const StyledRentalName = styled.h2`
  font-size: ${pxToRem(40)};

  &:after {
    content: '';
    display: block;
    border-bottom: 5px solid ${(props) => props.theme.secondaryColor};
    padding-top: 15px;
    width: 70px;
    transition-duration: 0.3s;
  }

  ${StyledRental}:hover &:after {
    border-bottom-color: ${(props) => props.theme.primaryColorShade2};
    width: 130px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: ${pxToRem(32)};
  }
`;

const StyledPropertyType = styled.h3`
  font-size: ${pxToRem(29)};
  line-height: 160%;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: ${pxToRem(22)};
  }
`;

const StyledRentalDesc = styled.p`
  margin: 30px 0 15px;
  line-height: 200%;
  color: ${(props) => props.theme.secondaryColorShade3};
  font-size: ${pxToRem(13)};
  font-weight: 500;
`;

const StyledRentalHighlights = styled.aside`
  display: flex;
  padding: 15px 0;
`;

const StyledRentalHighlight = styled.div`
  display: flex;
  width: 50%;
`;

const StyledRentalHighlightContent = styled.div`
  margin: 0 15px;
  font-size: ${pxToRem(19)};
  font-weight: 500;
  color: ${(props) => props.theme.secondaryColor};

  span {
    display: block;
    line-height: 180%;
    font-size: ${pxToRem(13)};
    font-weight: 600;
    color: ${(props) => props.theme.secondaryColorShade4};
  }
`;

const StyledKeyFiguresWrapper = styled.section`
  background: url(${bgKeyFiguresImg}) fixed no-repeat center center / cover;
  padding: 90px 0;
`;

const StyledKeyFiguresContent = styled.div`
  display: grid;
  gap: 80px;
  grid-template-columns: 33% 1fr;
  color: ${(props) => props.theme.white};

  @media (max-width: ${breakpoints.md}px) {
    display: block;
  }
`;

const StyledKeyFigures = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const StyledKeyFiguresTitle = styled.div`
  margin: 10px 0;
  line-height: 130%;
  font-size: ${pxToRem(65)};
  font-weight: 500;
  text-shadow: 8px 8px 0 rgb(150 150 150 / 30%);
`;

const StyledKeyFiguresSubTitle = styled.span`
  margin: 10px 0;
  line-height: 130%;
  font-size: ${pxToRem(14)};
  font-weight: 600;
  text-transform: uppercase;
`;

const Home: React.FC = () => {
  const theme = useContext(ThemeContext);
  const isMobile = useContext(MobileContext);

  const [experiencesCountUp, setExperiencesCountUp] = useState(0);
  const [rentalCountUp, setRentalCountUp] = useState(0);

  const [keyFiguresRef, keyFiguresIsInView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (keyFiguresIsInView) {
      setExperiencesCountUp(11);
      setRentalCountUp(3);
    }
  }, [keyFiguresIsInView]);

  return (
    <main style={{ paddingTop: `${navBarHeight + 1}px` }} data-testid="home">
      <StyledHero>
        <StyledHeroContent>
          <StyledHeroTitle>Locations</StyledHeroTitle>
          <StyledHeroSubTitle>à Saint-Malo</StyledHeroSubTitle>
        </StyledHeroContent>
      </StyledHero>
      <ParallaxProvider>
        <section style={{ marginTop: '90px' }}>
          <Container>
            {rentalData.map((rental, index) => {
              const reverse = index % 2 === 1;

              return (
                <StyledRental key={rental.slug} reverse={reverse}>
                  <FrameImg urlImg={rental.mainImg} altImg={rental.mainImgAlt} reverse={reverse} isParallax={true} />
                  <StyledRentalInfo reverse={reverse}>
                    <Parallax y={['-80px', '80px']} disabled={isMobile}>
                      <div>
                        <StyledPropertyType>{rental.propertyType}</StyledPropertyType>
                        <StyledRentalName>{rental.title}</StyledRentalName>
                      </div>
                      <StyledRentalDesc>{rental.mainDescription}</StyledRentalDesc>
                      <StyledRentalHighlights>
                        <StyledRentalHighlight>
                          <div>
                            <FontAwesomeIcon icon={faUmbrellaBeach} color={theme.primaryColorShade2} size="2x" />
                          </div>
                          <StyledRentalHighlightContent>
                            {rental.distanceFromBeach}m <span>de la plage</span>
                          </StyledRentalHighlightContent>
                        </StyledRentalHighlight>
                        <StyledRentalHighlight>
                          <div>
                            <FontAwesomeIcon icon={faBed} color={theme.primaryColorShade2} size="2x" />
                          </div>
                          <StyledRentalHighlightContent>
                            {rental.maxPeople} <span>voyageurs</span>
                          </StyledRentalHighlightContent>
                        </StyledRentalHighlight>
                      </StyledRentalHighlights>
                      <CTALink
                        to={routeWithParam(routes.RENTAL, {
                          propertyType: rental.propertyType.toLowerCase(),
                          slug: rental.slug,
                        })}
                        style={{ marginBottom: '15px' }}
                      >
                        Découvrir
                      </CTALink>
                    </Parallax>
                  </StyledRentalInfo>
                </StyledRental>
              );
            })}
          </Container>
        </section>
      </ParallaxProvider>
      <StyledKeyFiguresWrapper ref={keyFiguresRef} data-testid="home-key-figures">
        <Container>
          <StyledKeyFiguresContent>
            <FrameText>
              Profitez de <br /> vos vacances <br /> à Saint-Malo
            </FrameText>
            <GridGenerator
              columns={2}
              additionalColumnSettings={[
                {
                  maxScreenSize: breakpoints.md,
                  columns: 1,
                },
              ]}
              gap="30px"
            >
              <div>
                <StyledKeyFigures>
                  <StyledKeyFiguresTitle>
                    <CountUp end={experiencesCountUp} duration={1} />
                  </StyledKeyFiguresTitle>
                  <StyledKeyFiguresSubTitle>
                    années d'expériences
                    <br /> dans la location
                  </StyledKeyFiguresSubTitle>
                </StyledKeyFigures>
              </div>
              <div>
                <StyledKeyFigures>
                  <StyledKeyFiguresTitle>
                    <CountUp end={rentalCountUp} duration={1} />
                  </StyledKeyFiguresTitle>
                  <StyledKeyFiguresSubTitle>
                    logements disponibles
                    <br /> sur Saint-Malo
                  </StyledKeyFiguresSubTitle>
                </StyledKeyFigures>
              </div>
            </GridGenerator>
          </StyledKeyFiguresContent>
        </Container>
      </StyledKeyFiguresWrapper>
    </main>
  );
};

export default Home;
