import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

import pxToRem from '../../helpers/pxToRem';

interface CTALinkProps {
  to: string;
  style?: any;
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  display: inline-block;
  margin-left: -20px;
  padding-left: 10px;
  color: ${(props) => props.theme.primaryColorShade2};
  transition-duration: 0.3s;
  opacity: 0;
`;

const StyledCTALink = styled(Link)`
  display: inline-block;
  border-radius: 50px;
  background: ${(props) => props.theme.secondaryColor};
  padding: 12px 17px;
  color: ${(props) => props.theme.white};
  font-size: ${pxToRem(14)};
  font-weight: 500;
  transition-duration: 0.3s;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.primaryColorShade2};
    padding-right: 15px;
    color: ${(props) => props.theme.white};

    ${StyledFontAwesomeIcon} {
      margin-left: 0;
      color: ${(props) => props.theme.white};
      opacity: 1;
    }
  }
`;

const CTALink: React.FC<CTALinkProps> = (props) => {
  const { to, style, children } = props;

  return (
    <StyledCTALink to={to} style={style}>
      {children}
      <StyledFontAwesomeIcon icon={faLongArrowAltRight} />
    </StyledCTALink>
  );
};

export default CTALink;
