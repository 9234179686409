import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import routes from './constants/routes';
import lightTheme from './constants/lightTheme';

import MobileProvider from './components/MobileProvider';
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import RentalInfos from './components/RentalInfos';
import NotFound from './components/NotFound';
import GoogleAnalytics from './components/GoogleAnalytics';
import ScrollToTop from './components/ScrollToTop';
import { GlobalStyle } from './components/shared/globalStyle';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <ThemeProvider theme={lightTheme}>
        <MobileProvider>
          <GlobalStyle />
          <Header />
          <Switch>
            <Route exact path={routes.HOME} component={Home} />
            <Route exact path={routes.RENTAL} component={RentalInfos} />
            <Route path="*" component={NotFound} />
          </Switch>
          <Footer />
        </MobileProvider>
        <GoogleAnalytics />
      </ThemeProvider>
    </Router>
  );
};

export default App;
