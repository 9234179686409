import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOnClickOutside = (ref: any, handler: any): void => {
  useEffect(() => {
    const listener = (e: any): void => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }
      handler(e);
    };
    document.addEventListener('click', listener);

    return (): void => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handler]);
};
