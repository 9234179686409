import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../constants/styleConstants';
import pxToRem from '../helpers/pxToRem';
import { navBarHeight } from './Header';

const StyledWrapper = styled.div`
  padding: ${navBarHeight + 1 + 90}px 20px 30px;
  background: ${(props) => props.theme.secondaryColor};
  height: 80vh;
  text-align: center;
  color: ${(props) => props.theme.white};

  @media (max-width: ${breakpoints.md}px) {
    padding-top: ${navBarHeight + 1 + 60}px;
  }
`;

const Styled404 = styled.div`
  margin: 20px 0;
  font-size: ${pxToRem(180)};
  font-weight: 600;
  text-shadow: 8px 8px 0 rgba(150, 150, 150, 0.3);

  @media (max-width: ${breakpoints.sm}px) {
    font-size: ${pxToRem(120)};
  }
`;

const StyledTitle = styled.h1`
  color: ${(props) => props.theme.white};
  font-size: ${pxToRem(30)};
  font-weight: 600;
  text-shadow: 8px 8px 0 rgba(150, 150, 150, 0.3);
`;

const StyledText = styled.p`
  margin-top: 30px;
  color: ${(props) => props.theme.secondaryColorShade4};
  font-size: ${pxToRem(18)};
`;

const NotFound: React.FC = () => {
  return (
    <StyledWrapper>
      <Styled404>404</Styled404>
      <StyledTitle>Page not found</StyledTitle>
      <StyledText>Nous sommes désolé mais la page que vous recherché n'existe pas.</StyledText>
    </StyledWrapper>
  );
};

export default NotFound;
